<template>
  <div class="playout-dashboard-container" ref="playoutDashboardContainer">
    <v-card style="padding: 10px;background: var(--v-sidebarColorLight-darken2); overflow: hidden;margin-bottom: 8px">
      <v-progress-linear v-if="isLoading" style="position: absolute; bottom: 0; left: 0; width: 100%;" indeterminate
        height="4" color="grey darken-3"></v-progress-linear>
        
      <!-- <span style="position: absolute;text-transform: uppercase;font-weight: 900;letter-spacing: 5px;color: #414141;text-align: center;width: 100%;pointer-events: none;padding-top: 10px;">
        <span>Playout</span>
        <v-icon style="padding-left: 5px; margin-top: -2px;color:inherit;">mdi-animation-play-outline</v-icon>
      </span> -->

      <v-row no-gutters style="height: 41px;">
        <!-- Left column: K Number search -->
        <v-col cols="auto" style="display: flex; width: 160px;">
          <v-text-field ref="searchTerm" v-model="searchTerm" label="K Number" hide-details="auto" outlined dark dense :disabled="kNumber===null"
            style="max-width: 120px; border-top-right-radius: 0; border-bottom-right-radius: 0;"></v-text-field>
          <v-btn small height="40px"
            style="width: 40px; min-width: 40px; border-top-left-radius: 0; border-bottom-left-radius: 0;"
            color="primary" :disabled="!fetchingAPIData && !searchTerm" :loading="fetchingAPIData"
            @click="fetchAPIData">
            <v-icon v-if="!kNumber">mdi-magnify</v-icon>
            <v-icon v-else>mdi-refresh</v-icon>
          </v-btn>
        </v-col>

        <!-- Middle column: Audience select -->
        <v-col style="flex-grow: 1; padding: 0 10px;min-width: 0px !important;" v-show="isFilteredDataNotEmpty">
          <v-select v-if="isFilteredDataNotEmpty" v-model="selectedRouteRequest"
            :items="initializedSupportInfo.routeRequest" item-text="name" item-value="id" label="Audience" outlined
            dense style="width: 100%"
            ></v-select>
        </v-col>

        <!-- Right column: Buttons -->
        <v-col cols="auto" style="display: flex; width: 166px;" v-show="isFilteredDataNotEmpty">
          <v-btn ref="filterButton" @click="toggleMoreFilters" :color="filtersActive ? 'primary' : undefined" small
            style=" min-width: 40px; height: 40px; margin-right: 10px;transition: background-color 250ms, border-color 250ms;">
            <v-icon>mdi-filter</v-icon>
            <v-icon right>{{ isFilterExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>

          <v-menu offset-y nudge-left="15">
            <template v-slot:activator="{ on, attrs }">
              <v-btn small :loading="downloadingReport" v-bind="attrs" v-on="on"
                style="width: 40px; min-width: 40px; height: 40px; margin-right: 10px;">
                <v-icon>mdi-file-download-outline</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="downloadReport('Daily')">
                <v-list-item-title>Daily</v-list-item-title>
              </v-list-item>
              <v-list-item @click="downloadReport('Hourly')">
                <v-list-item-title>Hourly</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn small @click="toggleFullscreen" style="width: 40px; min-width: 40px; height: 40px;">
            <v-icon>mdi-fullscreen</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <!-- <v-row no-gutters style="margin-top: 10px;">
        <PlayoutFilters :defaultDateTimeRange="defaultDateTimeRange" :initializedSupportInfo="initializedSupportInfo" />
      </v-row> -->
      <v-expand-transition>
        <div v-show="isFilterExpanded" ref="overlay">
          <v-row dense>
            <v-col cols="12" sm="4">
              <date-range-picker 
                v-model="dateTimeRange"
                :opens="defaultDateTimeRange[0] ? 'inline' : 'left'"
                singleDatePicker="range"
                :locale-data="{ firstDay: 1, format: 'dd/mm/yyyy' }" 
                :ranges="false"
                :min-date="defaultDateTimeRange[0]"
                :max-date="defaultDateTimeRange[1]"
                :time-picker="false"
                :auto-apply="true"
                @update="updateSelectedDateTimeRange"
              >
                <div slot="footer" slot-scope="data" class="slot">
                  <div style="height:95px;">
                    <PlayoutBrushChart :brushData="brushData" :dateTimeRange="brushDateTimeRange"
                      style="margin-top:-30px;" @selectionChanged="handleBrushSelectionChanged" />
                  </div>
                  <div class="playout-overline"
                    style="letter-spacing: 1px;font-size: 12px;color: white;text-align: center;padding: 10px 25px;margin-top:0px">
                    {{ data.rangeText}}
                  </div>
                  <v-btn v-show="selectedDateTimeRange.length > 0" @click="resetSelectedDateTimeRange" icon x-small fab
                    style="position: absolute;bottom: 1px;right: 0px;">
                    <v-icon small>mdi-refresh</v-icon>
                  </v-btn>
                </div>
              </date-range-picker>
            </v-col>
            <v-col cols="12" sm="8">
              <v-row dense>
                <v-col cols="12" sm="6">
                  <div class="playout-overline">Formats</div>

                  <v-autocomplete
                    v-model="selectedFormatDelivers"
                    class="playout-filter"
                    :items="initializedSupportInfo.formatDeliver"
                    item-text="name"
                    item-value="id"
                    label="Formats"
                    multiple
                    outlined
                    dense
                    hide-details
                    
                    chips
                    deletable-chips
                    small-chips
                  >
                  </v-autocomplete>

                  <v-autocomplete
                    v-model="selectedFormatGroups"
                    class="playout-filter"
                    :items="initializedSupportInfo.formatGroup"
                    item-text="name"
                    item-value="id"
                    label="Format Groups"
                    multiple
                    outlined
                    dense
                    hide-details
                    
                    chips
                    deletable-chips
                    small-chips
                  >
                  </v-autocomplete>

                  <v-autocomplete
                    v-model="selectedCreativeNames"
                    class="playout-filter"
                    :items="initializedSupportInfo.creativeName"
                    item-text="name"
                    item-value="id"
                    label="Creative Names"
                    multiple
                    outlined
                    dense
                    hide-details
                    
                    chips
                    deletable-chips
                    small-chips
                  >
                  </v-autocomplete>

                  <div class="playout-overline">Media</div>

                  <v-autocomplete
                    v-model="selectedMediaOwners"
                    class="playout-filter"
                    :items="initializedSupportInfo.mediaOwner"
                    item-text="name"
                    item-value="id"
                    label="Media Owners"
                    multiple
                    outlined
                    dense
                    hide-details
                    
                    chips
                    deletable-chips
                    small-chips
                  >
                  </v-autocomplete>

                  <v-autocomplete
                    v-model="selectedSalesCodes"
                    class="playout-filter"
                    :items="initializedSupportInfo.salesCode"
                    item-text="name"
                    item-value="id"
                    label="Sales Codes"
                    multiple
                    outlined
                    dense
                    hide-details
                    
                    chips
                    deletable-chips
                    small-chips
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6">
                  <div class="playout-overline">Locations</div>
                  <v-autocomplete
                    v-model="selectedTvRegions"
                    class="playout-filter"
                    :items="initializedSupportInfo.tvRegion"
                    item-text="name"
                    item-value="id"
                    label="TV Regions"
                    multiple
                    outlined
                    dense
                    hide-details
                    
                    chips
                    deletable-chips
                    small-chips
                  >
                  </v-autocomplete>

                  <v-autocomplete
                    v-model="selectedConurbations"
                    class="playout-filter"
                    :items="initializedSupportInfo.conurbation"
                    item-text="name"
                    item-value="id"
                    label="Conurbations"
                    multiple
                    outlined
                    dense
                    hide-details
                    
                    chips
                    deletable-chips
                    small-chips
                  >
                  </v-autocomplete>

                  <v-autocomplete
                    v-model="selectedTowns"
                    class="playout-filter"
                    :items="initializedSupportInfo.town"
                    item-text="name"
                    item-value="id"
                    label="Towns"
                    multiple
                    outlined
                    dense
                    hide-details
                    
                    chips
                    deletable-chips
                    small-chips
                  >
                  </v-autocomplete>

                  <v-row dense>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="selectedAddresses"
                        class="playout-filter"
                        :items="initializedSupportInfo.address"
                        item-text="name"
                        item-value="id"
                        label="Addresses"
                        multiple
                        outlined
                        dense
                        hide-details
                        
                        chips
                    deletable-chips
                    small-chips
                      >
                  </v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="selectedPostCodes"
                        class="playout-filter"
                        :items="initializedSupportInfo.postCode"
                        item-text="name"
                        item-value="id"
                        label="Post Codes"
                        multiple
                        outlined
                        dense
                        hide-details
                        
                        chips
                    deletable-chips
                    small-chips
                      >
                  </v-autocomplete>
                    </v-col>
                  </v-row>

                  <div class="playout-overline">Descriptions</div>

                  <v-row dense>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="selectedLocationDescs"
                        class="playout-filter"
                        :items="initializedSupportInfo.locationDesc"
                        item-text="name"
                        item-value="id"
                        label="Location Descriptions"
                        multiple
                        outlined
                        dense
                        hide-details
                        
                        chips
                    deletable-chips
                    small-chips
                      >
                  </v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="selectedEnvDescs"
                        class="playout-filter"
                        :items="initializedSupportInfo.envDesc"
                        item-text="name"
                        item-value="id"
                        label="Environmental Descriptions"
                        multiple
                        outlined
                        dense
                        hide-details
                        
                        chips
                    deletable-chips
                    small-chips
                      >
                  </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row style="height: 40px;margin-bottom: 2px;">
                <span style="text-transform: uppercase;margin-left: 15px;font-weight: 900;letter-spacing: 5px;color: #414141;text-align: center;pointer-events: none;padding-top: 10px;">
                  <span>Playout</span>
                  <v-icon style="padding-left: 5px; margin-top: -2px;color:inherit;">mdi-animation-play-outline</v-icon>
                </span>
                <div style="display: flex;position: absolute;right: 10px;">
                  <v-btn height="40" @click="resetFilters" style="flex: 2; margin-right: 10px;" color="secondary"><v-icon>mdi-filter-remove</v-icon></v-btn>
                  <v-btn height="40" @click="updateFilters" color="primary"><v-icon>mdi-filter-check</v-icon></v-btn>
                </div>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-expand-transition>
    </v-card>
    <div v-if="isFilteredDataNotEmpty">
      <v-row dense>
        <v-col v-if="miniChartData.plays.totalPlays > 0" :key="1" cols="12" :md="getColSize()">
          <PlayoutKPI :item="{ 
            name: 'Plays', 
            actuals: miniChartData.plays.totalPlays,
            targets: null,
            performance: null,
            miniChartData: miniChartData.plays}"/>
        </v-col>

        <v-col v-if="miniChartData.impacts.totalImpacts > 0" :key="2" cols="12" :md="getColSize()">
          <PlayoutKPI :item="{ 
            name: 'Impacts', 
            actuals: miniChartData.impacts.totalImpacts,
            targets: null,
            performance: null,
            miniChartData: miniChartData.impacts}"/>
        </v-col>

        <v-col v-if="miniChartData.reach.totalReach > 0" :key="3" cols="12" :md="getColSize()">
          <PlayoutKPI :item="{ 
            name: 'Others', 
            reach: miniChartData.reach.totalReach,
            cover: miniChartData.cover.totalCover,
            frequency: miniChartData.impacts.totalImpacts / miniChartData.reach.totalReach,
            miniChartData: miniChartData.reach}"/>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="8">
          <v-card style="background: var(--v-sidebarColorLight-darken2);height: 500px; margin:0px">
            <div style="padding: 20px 20px 10px 20px;">
              <span style="text-transform: uppercase;font-weight: 500;letter-spacing: 4px; color: grey;"> Master</span>
            </div>
            <div style="width: 100%;height: calc(100% - 51px);    padding: 0px 26px 34px 15px;">
              <PlayoutMasterChart :masterData="masterChartData" style="width: 100%; height: 100%;" />
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card style="background: var(--v-sidebarColorLight-darken2);height: 500px;display: flex; margin:0px">
            <PlayoutMap :mapData="mapData" style="width: 100%; height: 100%;" />
          </v-card>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col key="1" cols="12" lg="4">
          <v-card style="background: var(--v-sidebarColorLight-darken2); height: 400px; margin:0px">
            <div style="padding: 20px 20px 10px 20px;">
              <span style="text-transform: uppercase;font-weight: 500;letter-spacing: 4px; color: grey;"> {{
        mediaOwnerChartName }}</span>
            </div>
            <div style="padding: 10px 20px 20px 20px;height: calc(100% - 51px);">
              <PlayoutGroupTypeChart :groupTypeData="mediaOwnerChartData" />
            </div>
          </v-card>
        </v-col>
        <v-col key="2" cols="12" lg="4">
          <v-card style="background: var(--v-sidebarColorLight-darken2); height: 400px; margin:0px">
            <div style="padding: 20px 20px 10px 20px;">
              <span style="text-transform: uppercase;font-weight: 500;letter-spacing: 4px; color: grey;"> {{
        formatChartName
      }} </span>
            </div>
            <div style="padding: 10px 20px 20px 20px;height: calc(100% - 51px);">
              <PlayoutGroupTypeChart :groupTypeData="formatChartData" />
            </div>
          </v-card>
        </v-col>
        <v-col key="3" cols="12" lg="4">
          <v-card style="background: var(--v-sidebarColorLight-darken2); height: 400px; margin:0px">
            <div style="padding: 20px 20px 10px 20px;">
              <span style="text-transform: uppercase;font-weight: 500;letter-spacing: 4px; color: grey;"> {{
                tvRegionChartName }} </span>
            </div>
            <div style="padding: 10px 20px 20px 20px;height: calc(100% - 51px)">
              <PlayoutGroupTypeChart :groupTypeData="tvRegionChartData" />
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- JSON Data Card -->
    <!-- <v-card
      style="background: black; color: white; z-index: 9999; height: 1000px; margin-bottom: 20px; overflow: hidden;">
      <v-card-text style="height: 100%; overflow-y: auto;">
        <pre style="color: white; margin: 0;">{{ this.dataObject }}</pre>
      </v-card-text>
    </v-card> -->
  </div>
</template>

<script>
// Services
import ReportingController from '@/services/controllers/Reporting'
import { debounce } from 'lodash';

// Components
import PlayoutMap from './PlayoutMap.vue';
import PlayoutBrushChart from './PlayoutBrushChart.vue';
import PlayoutMasterChart from './PlayoutMasterChart.vue';
import PlayoutMiniChart from './PlayoutMiniChart.vue';
import PlayoutGroupTypeChart from './PlayoutGroupTypeChart.vue';
import PlayoutFilters from './PlayoutFilters.vue';
import DateRangePicker from 'vue2-daterange-picker'
import './vue2-daterange-picker.css'
import PlayoutKPI from './PlayoutKPI.vue';

export default {
  name: 'PlayoutDashboard',
  props: {
    kNumber: {
      type: String,
      required: false
    },
    incomingRouteRequest : {
      type: Object,
      required: false
    }
  },
  components: {
    DateRangePicker,
    PlayoutMap,
    PlayoutBrushChart,
    PlayoutMasterChart,
    PlayoutMiniChart,
    PlayoutGroupTypeChart,
    PlayoutFilters, 
    PlayoutKPI
  },
  data: () => ({
    apiData: {},// require('@/assets/dev/json/Playout Visualizer Data - Example 4 - K56124 - Daily.json'),// require('@/assets/dev/json/Playout Visualizer Data - Example 6 - K56106 - Daily.json'), // Example Loader: require('@/assets/dev/json/Playout Visualizer Data - Example 4 - K56124 - Daily.json'),

    searchTerm: '',
    loadedSearchTerm: '',
    granularity: 'Daily',
    fetchingAPIData: false,
    downloadingReport: false,

    //filters
    isFilterExpanded: false,
    dateTimeRangeFilter: [], // example datetime range ["2024-01-16T00:00:00", "2024-01-20T00:00:00"]
    mediaOwnerFilter: [],
    formatDeliverFilter: [],
    conurbationFilter: [],
    tvRegionFilter: [],
    creativeNameFilter: [],
    salesCodeFilter: [],
    formatGroupFilter: [],
    locationDescFilter: [],
    envDescFilter: [],
    addressFilter: [],
    postCodeFilter: [],
    townFilter: [],

    //Selection
    selectedDateTimeRange: [],
    selectedMediaOwners: [],
    selectedFormatDelivers: [],
    selectedConurbations: [],
    selectedTvRegions: [],
    selectedRouteRequest: [],
    selectedCreativeNames: [],
    selectedSalesCodes: [],
    selectedFormatGroups: [],
    selectedLocationDescs: [],
    selectedEnvDescs: [],
    selectedAddresses: [],
    selectedPostCodes: [],
    selectedTowns: [],

    //date picker 
    dateTimeRange: {
      startDate: null,
      endDate: null
    },
    brushDateTimeRange: {
      startDate: null,
      endDate: null
    },

    //UI data
    isLoading: false,
    dataObject: {},
    displayTextBox: false,
    filtersActive: false,

    chart1Param: 'mediaOwner',
    chart2Param: 'formatDeliver',
    chart3Param: 'tvRegion',

    chartOptions: ['mediaOwner', 'formatDeliver', 'tvRegion', 'creativeName', 'salesCode',
      'formatGroup', 'locationDesc', 'envDesc', 'address', 'postCode', 'town', 'conurbation'],
  }),
  mounted() {
    console.log("incomingRouteRequest created",this.incomingRouteRequest);
  },
  watch: {
    incomingRouteRequest(newVal) {
      console.log("incomingRouteRequest changed on playout",newVal);
      if (newVal) {
        this.selectedRouteRequest = newVal.id;
        console.log("selectedRouteRequest",this.selectedRouteRequest);
      }
    },
    selectedRouteRequest(newVal) {
      console.log("selectedRouteRequest changed",newVal);
    },
    filteredSupportInfo(newInfo) {
      // Select Route Request for first time loading
      console.log("filteredSupportInfo",newInfo);
      if (this.selectedRouteRequest.length === 0) {
        const newRouteRequest = newInfo.get('routeRequest');
        if (!newRouteRequest) {
          return;
        }
        for (const [id, item] of newRouteRequest.entries()) {
          if (item.name.includes('All Adult')) {
            this.selectedRouteRequest = id;
            break;
          }
        }
      }
    },
  },
  mounted() {
    if (this.kNumber) {
      // if a kNumber is provided, search for it
      this.searchTerm = this.kNumber;
      this.fetchAPIData();
    }
  },

  computed: {

    brushData() {
      if (!this.apiData || !this.apiData.data || this.apiData.data.length === 0) {
        return { series: [], categories: [] };
      }

      const categories = [];
      const playsData = [];

      // for (const item of this.apiData.data) {
      //   categories.push(item.dateTime);
      //   playsData.push(item.grandTotals.plays);
      // }

      for (const item of this.apiData.data) {
        categories.push(item.dateTime);
        var totalPlays = 0;
        for (const frame of item.frames) {
          totalPlays += frame.plays;
        }
        playsData.push(totalPlays);
      }

      return {
        series: [{ name: 'Plays', data: playsData }],
        categories: categories
      };
    },
    //get total start and end datetime
    defaultDateTimeRange() {
      // Check if this.apiData exists and has a non-empty 'data' array
      if (this.apiData && this.apiData.data && this.apiData.data.length > 0) {
        var minDate = new Date(this.apiData.data[0].dateTime);
        var maxDate = minDate;
        const apiDataLength = this.apiData.data.length;
        if (apiDataLength > 1) {
          maxDate = new Date(this.apiData.data[apiDataLength - 1].dateTime);
        }
        this.dateTimeRange = {
          startDate: minDate,
          endDate: maxDate
        }
        return [
          minDate,
          maxDate
        ];
      } else {
        // Handle the case where this.apiData is null/undefined or data is empty
        return [];
      }
    },
    // #region Initialize variables
    initializedSupportInfo() {
      return {
        frameInfo: this.apiData.supportInfo?.frameInfo || [],
        frameId: this.apiData.supportInfo?.frameId || [],
        creativeName: this.apiData.supportInfo?.creativeName || [],
        mediaOwner: this.apiData.supportInfo?.mediaOwner || [],
        formatDeliver: this.apiData.supportInfo?.formatDeliver || [],
        salesCode: this.apiData.supportInfo?.salesCode || [],
        formatGroup: this.apiData.supportInfo?.formatGroup || [],
        locationDesc: this.apiData.supportInfo?.locationDesc || [],
        envDesc: this.apiData.supportInfo?.envDesc || [],
        address: this.apiData.supportInfo?.address || [],
        postCode: this.apiData.supportInfo?.postCode || [],
        town: this.apiData.supportInfo?.town || [],
        tvRegion: this.apiData.supportInfo?.tvRegion || [],
        conurbation: this.apiData.supportInfo?.conurbation || [],
        routeRequest: this.apiData.supportInfo?.routeRequest || [],
      };
    },
    // endregion

    // #region Apply filtering
    filteredDateTime() {
      // Only compute if apiData is available
      if (!this.apiData || !this.apiData.data || this.apiData.data.length === 0) {
        return [];
      }

      let [startDate, endDate] = this.dateTimeRangeFilter.length
        ? this.dateTimeRangeFilter
        : this.defaultDateTimeRange;

      const startTime = new Date(startDate).toISOString();
      const endTime = new Date(endDate).toISOString();
      this.dateTimeRange = {
        startDate: startTime,
        endDate: endTime,
      }
      return [startTime, endTime];
    },
    filteredFramesLookup() {
      const filteredFrames = {
        frames: [],
      };

      for (const frame of this.initializedSupportInfo.frameInfo) {
        if (
          (!this.mediaOwnerFilter.length || this.mediaOwnerFilter.includes(frame.mediaOwnerRefId)) &&
          (!this.formatDeliverFilter.length || this.formatDeliverFilter.includes(frame.formatDeliverRefId)) &&
          (!this.conurbationFilter.length || this.conurbationFilter.includes(frame.conurbationRefId)) &&
          (!this.tvRegionFilter.length || this.tvRegionFilter.includes(frame.tvRegionRefId)) &&
          (!this.creativeNameFilter.length || this.creativeNameFilter.includes(frame.creativeNameRefId)) &&
          (!this.salesCodeFilter.length || this.salesCodeFilter.includes(frame.salesCodeRefId)) &&
          (!this.formatGroupFilter.length || this.formatGroupFilter.includes(frame.formatGroupRefId)) &&
          (!this.locationDescFilter.length || this.locationDescFilter.includes(frame.locationDescRefId)) &&
          (!this.envDescFilter.length || this.envDescFilter.includes(frame.envDescRefId)) &&
          (!this.addressFilter.length || this.addressFilter.includes(frame.addressRefId)) &&
          (!this.postCodeFilter.length || this.postCodeFilter.includes(frame.postCodeRefId)) &&
          (!this.townFilter.length || this.townFilter.includes(frame.townRefId))
        ) {
          filteredFrames.frames.push(frame);
        }
      }

      const filteredFramesLookup = new Map();
      filteredFrames.frames.forEach(frame => {
        filteredFramesLookup.set(frame.frameIdRefId, frame);
      });

      return filteredFramesLookup;
    },
    filteredSupportInfo() {
      const filteredFramesLookup = this.filteredFramesLookup;
      const supportInfo = this.initializedSupportInfo;

      const filteredSupportInfo = new Map();

      // Step 1: Gather all `xxxRefId` fields
      const refIdFields = {};
      const refIdsMap = new Map();
      for (const key of Object.keys(supportInfo)) {
        if (Array.isArray(supportInfo[key])) {
          const refIdField = `${key}RefId`;
          refIdFields[key] = refIdField;
          refIdsMap.set(refIdField, new Set()); // Initialize set for each refIdField
        }
      }

      // Step 2: Loop all frames once to get corresponding `refIds`
      for (const frame of filteredFramesLookup.values()) {
        for (const refIdField of Object.entries(refIdFields)) {
          if (refIdField in frame) {
            refIdsMap.get(refIdField).add(frame[refIdField]);
          }
        }
      }

      // Step 3: Filter `supportInfo` based on the gathered `refIds`
      for (const [key, value] of Object.entries(supportInfo)) {
        if (Array.isArray(value)) {
          const refIdField = refIdFields[key];
          let filteredItems = value;
          if (refIdsMap.get(refIdField) && refIdsMap.get(refIdField).size > 0) {
            filteredItems = value.filter(item => refIdsMap.get(refIdField).has(item.id));
          }
          const filteredMap = new Map(filteredItems.map(item => [item.id, item]));
          filteredSupportInfo.set(key, filteredMap);
        }
      }

      // Replace frameInfo in filteredSupportInfo with the filtered frames from filteredFramesLookup
      filteredSupportInfo.set('frameInfo', new Map(filteredFramesLookup));

      return filteredSupportInfo;
    },
    filteredData() {
      if (!this.apiData || !this.apiData.data) {
        return { data: [] };
      }
      
      this.isLoading = true;
      // force update dom
      this.$forceUpdate();

      const [startTime, endTime] = this.filteredDateTime;
      const filteredFramesLookup = this.filteredFramesLookup;

      const filteredData = {
        data: []
      };

      // Create template
      const types = [
        "mediaOwner",
        "tvRegion",
        "formatDeliver",
        "creativeName",
        "salesCode",
        "formatGroup",
        "locationDesc",
        "envDesc",
        "address",
        "postCode",
        "town",
        "conurbation"
      ];
      const filteredItemTemplate = this.createFilteredItemTemplate(types);
      const groupedTotalTemplate = this.createGroupedTotalTemplate();

      // Convert startTime and endTime to Date objects for comparison
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);

      for (const item of this.apiData.data) {
        if (!item) continue;

        // Convert item's dateTime to a Date object
        const itemDate = new Date(item.dateTime);

        // Check if the item's date is within the range (inclusive of start date)
        if (itemDate < startDate || itemDate > endDate)
          continue;

        const filteredItem = JSON.parse(JSON.stringify(filteredItemTemplate));
        filteredItem.dateTime = itemDate.toISOString();

        if (!item.frames || !Array.isArray(item.frames)) {
          console.warn('Invalid frames data for item:', item);
          continue;
        }

        for (const frame of item.frames) {
          if (!frame) continue;

          const matchingFrameInfo = filteredFramesLookup.get(frame.frameIdRefId);
          if (!matchingFrameInfo) continue;

          filteredItem.frames.push(frame);
          this.aggregateTotals(filteredItem.grandTotals, frame);

          for (const group of filteredItem.groupedTotals) {
            if (!group || !group.data) continue;

            let groupedTotal = group.data.find(
              (data) => data && data.refId === matchingFrameInfo[`${group.type}RefId`]
            );
            if (!groupedTotal) {
              groupedTotal = JSON.parse(JSON.stringify(groupedTotalTemplate));
              groupedTotal.refId = matchingFrameInfo[`${group.type}RefId`];
              group.data.push(groupedTotal);
            }
            this.aggregateTotals(groupedTotal, frame);
          }
        }
        filteredData.data.push(filteredItem);
      }

      this.isLoading = false;
      return filteredData;
    },
    // #endregion
    // #region Computed Chart Data Values
    dailyFigures() {
      // Add valueType here, and get data by dailyChartData.plays, dailyChartData.impacts, etc...
      const valueType = {
        plays: 'plays',
        impacts: 'i',
        impactsP: 'ip',
        impactsV: 'iv',
        reach: 'r',
        reachP: 'rp',
        reachV: 'rv',
        grp: 'grp',
        cover: 'c',
        averageFrequency: 'af'
      };
      const getTotalLabelName = (type) => `total${type.charAt(0).toUpperCase() + type.slice(1)}`;

      const data = this.filteredData?.data;

      const results = Object.keys(valueType).reduce((acc, type) => {
        acc[type] = { series: [], categories: [], [getTotalLabelName(type)]: 0 };
        return acc;
      }, {});

      if (!data || !data.length) {
        return results;
      }

      const categories = [];
      const aggregateTotals = Object.keys(valueType).reduce((acc, type) => {
        acc[type] = { values: [], total: 0 };
        return acc;
      }, {});

      for (const item of data) {
        categories.push(item.dateTime);
        for (const type of Object.keys(valueType)) {
          let value = 0;
          if (type === 'plays') {
            value = item.grandTotals[valueType[type]] || 0;
          } else {
            value = item.grandTotals.rf
              .filter(rfItem => rfItem.rid === this.selectedRouteRequest)
              .reduce((sum, rfItem) => sum + (rfItem[valueType[type]] || 0), 0);
          }
          aggregateTotals[type].values.push(value);
          aggregateTotals[type].total += value;
        }
      }

      Object.keys(results).forEach(type => {
        results[type].series = [{ data: aggregateTotals[type].values }];
        results[type].categories = categories;
        results[type][getTotalLabelName(type)] = aggregateTotals[type].total;
      });

      return results;
    },
    // #endregion
    // #region Chart Data
    mapData() {
      const data = this.filteredData?.data;
      if (!data || !data.length) {
        return { data: [], maxIntensity: 0 };
      }

      const mapData = [];
      const mergedFrames = new Map();
      let maxIntensity = 0;

      for (const date of this.filteredData.data) {
        for (const frame of date.frames) {
          const matchingFrameInfo = this.filteredSupportInfo.get('frameInfo').get(frame.frameIdRefId);

          const key = `${matchingFrameInfo.lat},${matchingFrameInfo.long}`;
          const mergedFrame = mergedFrames.get(key) || this.initializeMergedFrame(matchingFrameInfo);
          mergedFrame.plays += frame.plays;
          mergedFrame.impacts += frame.impacts;
          mergedFrames.set(key, mergedFrame);

          maxIntensity = Math.max(maxIntensity, mergedFrames.get(key).plays);
        }
      }

      for (const [key, value] of mergedFrames) {
        mapData.push(value);
      }

      return { data: mapData, maxIntensity };
    },
    masterChartData() {
      const dailyPlays = this.dailyFigures.plays;
      const dailyImpacts = this.dailyFigures.impacts;

      const series = [];

      if (dailyPlays.series.length > 0 && dailyPlays.series[0].data.some(play => play !== 0)) {
        const cumulativePlays = dailyPlays.series[0].data.reduce((acc, value, index) => {
          acc.push((acc[index - 1] || 0) + value);
          return acc;
        }, []);
        series.push(
          { name: 'Plays - Daily', type: 'area', data: dailyPlays.series[0].data },
          { name: 'Plays - Cumulative', type: 'column', data: cumulativePlays }
        );
      }

      if (dailyImpacts.series.length > 0 && dailyImpacts.series[0].data.some(impact => impact !== 0)) {
        const cumulativeImpacts = dailyImpacts.series[0].data.reduce((acc, value, index) => {
          acc.push((acc[index - 1] || 0) + value);
          return acc;
        }, []);
        series.push(
          { name: 'Impacts - Daily', type: 'area', data: dailyImpacts.series[0].data },
          { name: 'Impacts - Cumulative', type: 'column', data: cumulativeImpacts }
        );
      }

      return {
        series,
        categories: dailyPlays.categories,
      };
    },
    miniChartData() {
      return {
        plays: this.dailyFigures.plays,
        impacts: this.dailyFigures.impacts,
        reach: this.dailyFigures.reach,
        cover: this.dailyFigures.cover,
        averageFrequency: this.dailyFigures.averageFrequency,
      };
    },

    mediaOwnerChartData() {
      return this.calculateGroupedChartData(this.chart1Param);
    },
    formatChartData() {
      return this.calculateGroupedChartData(this.chart2Param);
    },
    tvRegionChartData() {
      return this.calculateGroupedChartData(this.chart3Param);
    },
    mediaOwnerChartName() {
      return this.getChartName(this.chart1Param);
    },
    formatChartName() {
      return this.getChartName(this.chart2Param);
    },
    tvRegionChartName() {
      return this.getChartName(this.chart3Param);
    },
    isFilteredDataNotEmpty() {
      if (this.filtersActive) {
        return this.filtersActive;
      }

      const filteredData = this.filteredData;
      const isNotEmpty = filteredData && Array.isArray(filteredData.data) && filteredData.data.length > 0;
      return isNotEmpty;
    }
  },

  methods: {
    handleBrushSelectionChanged(newSelection) {
      if (newSelection && newSelection.length === 2) {
        // Update selectedDateTimeRange
        this.selectedDateTimeRange = newSelection;

        // Update dateTimeRange
        this.dateTimeRange = {
          startDate: new Date(newSelection[0]),
          endDate: new Date(newSelection[1])
        };
      }
    },
    resetSelectedDateTimeRange() {
      this.selectedDateTimeRange = [];
      this.dateTimeRange, this.brushDateTimeRange = {
        startDate: this.defaultDateTimeRange[0],
        endDate: this.defaultDateTimeRange[1]
      };
    },
    updateSelectedDateTimeRange() {
      if (this.dateTimeRange && this.dateTimeRange.startDate && this.dateTimeRange.endDate) {
        console.log('Updating selectedDateTimeRange');
        // Create new Date objects from the start and end dates
        const startDate = new Date(this.dateTimeRange.startDate);
        const endDate = new Date(this.dateTimeRange.endDate);

        console.log('Start Date:', startDate);
        console.log('End Date:', endDate);

        console.log('Min Start Date:', this.defaultDateTimeRange[0]);
        console.log('Max End Date:', this.defaultDateTimeRange[1]);

        // Set the time to 00:00:00 for both dates
        startDate.setUTCHours(0, 0, 0, 0);
        endDate.setUTCHours(0, 0, 0, 0);

        // Convert the dates back to ISO strings
        const startDateString = startDate.toISOString();
        const endDateString = endDate.toISOString();

        this.selectedDateTimeRange = [
          startDateString,
          endDateString
        ];

        this.brushDateTimeRange = {
          startDate,
          endDate
        };
      }
    },
    getColSize() {
      const visibleCards = ['plays', 'impacts', 'reach'].filter(card =>
        this.miniChartData[card][`total${card.charAt(0).toUpperCase() + card.slice(1)}`] > 0
      ).length;

      if (visibleCards === 1) return 12;
      if (visibleCards === 2) return 6;
      return 4;
    },
    // #region Data Manipulation in Computed: FilteredData // Modify here to make data in the shape of the chart
    createFilteredItemTemplate(types) {
      const toReadableFormat = (str) => {
        return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); });
      };
      const totalMapping = types.map(type => {
        const readableName = toReadableFormat(type);
        return {
          name: `By ${readableName}`,
          shortName: `by${readableName.replace(/\s+/g, '')}`,
          type: type,
          data: [],
        };
      });

      return {
        dateTime: [],
        frames: [],
        groupedTotals: totalMapping,
        grandTotals: {
          plays: 0,
          rf: [],
        },
      };
    },
    createGroupedTotalTemplate() {
      return {
        refId: null,
        plays: 0,
        rf: [],
      };
    },
    aggregateTotals(targetTotals, sourceFrame) {
      if (!targetTotals || !sourceFrame) return;

      const existingRfMap = new Map(targetTotals.rf ? targetTotals.rf.map(item => [item.rid, item]) : []);

      targetTotals.plays += sourceFrame.plays || 0;
      
      if (sourceFrame.rf && Array.isArray(sourceFrame.rf)) {
        for (let i = 0; i < sourceFrame.rf.length; i++) {
          const rfItem = sourceFrame.rf[i];
          if (rfItem) {
            let existingRfItem = existingRfMap.get(rfItem.rid);
            if (existingRfItem) {
              existingRfItem.i += rfItem.i || 0;
              existingRfItem.ip += rfItem.ip || 0;
              existingRfItem.iv += rfItem.iv || 0;
              existingRfItem.grp += rfItem.grp || 0;
              existingRfItem.r += rfItem.r || 0;
              existingRfItem.rp += rfItem.rp || 0;
              existingRfItem.rv += rfItem.rv || 0;
              existingRfItem.c += rfItem.c || 0;
              existingRfItem.af += rfItem.af || 0;
            } else {
              const newRfItem = { ...rfItem };
              targetTotals.rf.push(newRfItem);
              existingRfMap.set(rfItem.rid, newRfItem);
            }
          }
        }
      }
    },
    calculateGroupedChartData(type) {
      const data = this.filteredData?.data;
      if (!data || !data.length) {
        return { series: [], categories: [] };
      }

      const supportInfo = this.filteredSupportInfo.get(type);

      const categories = Array.from(supportInfo.values()).map(entry => entry.name);
      const playsData = new Array(supportInfo.size).fill(0);
      const impactsData = new Array(supportInfo.size).fill(0);

      const refIdToIndex = new Map(Array.from(supportInfo.keys()).map((id, index) => [id, index]));

      for (let item of data) {
        for (let total of item.groupedTotals) {
          if (total.type === type) {
            for (let groupItem of total.data) {
              const { refId, plays, rf } = groupItem;
              const index = refIdToIndex.get(refId);
              playsData[index] += plays;
              const impacts = rf
                .filter(rfItem => rfItem.rid === this.selectedRouteRequest)
                .reduce((sum, rfItem) => sum + (rfItem.i || 0), 0);
              impactsData[index] += impacts;
            }
          }
        }
      }

      const hasNonZeroPlays = playsData.some(play => play !== 0);
      const hasNonZeroImpacts = impactsData.some(impact => impact !== 0);

      const series = [];
      if (hasNonZeroPlays) {
        series.push({ name: 'Plays', data: playsData });
      }
      if (hasNonZeroImpacts) {
        series.push({ name: 'Impacts', data: impactsData });
      }

      return { series, categories };
    },
    initializeMergedFrame(matchingFrameInfo) {
      const supportInfo = this.filteredSupportInfo;
      return {
        location: { lat: matchingFrameInfo.lat, lng: matchingFrameInfo.long },
        plays: 0,
        impacts: 0,
        formatName: supportInfo.get('formatDeliver').get(matchingFrameInfo.formatDeliverRefId)?.name || null,
        mediaOwnerName: supportInfo.get('mediaOwner').get(matchingFrameInfo.mediaOwnerRefId)?.name || null,
        tvRegionName: supportInfo.get('tvRegion').get(matchingFrameInfo.tvRegionRefId)?.name || null,
        conurbationName: supportInfo.get('conurbation').get(matchingFrameInfo.conurbationRefId)?.name || null
      };
    },
    // #endregion
    // #region UI functions
    toggleMoreFilters() {
      this.isFilterExpanded = !this.isFilterExpanded;
    },
    getChartName(param) {
      const chartNames = {
        mediaOwner: 'Media Owner',
        formatDeliver: 'Formats on Deliver',
        tvRegion: 'TV Regions',
        creativeName: 'Creatives',
        salesCode: 'Sales Codes',
        formatGroup: 'Format Groups',
        locationDesc: 'Location Descriptions',
        envDesc: 'Environmental Descriptions',
        address: 'Addresses',
        postCode: 'Post Codes',
        town: 'Towns',
        conurbation: 'Conurbations'
      };
      return chartNames[param] || 'Chart';
    },
    toggleFullscreen() {
      const elem = this.$refs.playoutDashboardContainer;

      if (!document.fullscreenElement) {
        elem.requestFullscreen().catch(err => {
          console.error('Failed to enter fullscreen mode:', err);

        });
        // add class playout-dashboard-container-fullscreen to elem
        elem.classList.add('playout-dashboard-container-fullscreen');


      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
          elem.classList.remove('playout-dashboard-container-fullscreen');
        }
      }
    },
    updateFilters() {
      if (
        this.selectedDateTimeRange.length > 0 ||
        this.selectedMediaOwners.length > 0 ||
        this.selectedFormatDelivers.length > 0 ||
        this.selectedConurbations.length > 0 ||
        this.selectedTvRegions.length > 0 ||
        this.selectedCreativeNames.length > 0 ||
        this.selectedSalesCodes.length > 0 ||
        this.selectedFormatGroups.length > 0 ||
        this.selectedLocationDescs.length > 0 ||
        this.selectedEnvDescs.length > 0 ||
        this.selectedAddresses.length > 0 ||
        this.selectedPostCodes.length > 0 ||
        this.selectedTowns.length > 0
      ) {
        this.filtersActive = true;
      }
      else {
        this.filtersActive = false;
      };

      this.debouncedUpdateFilters();
      this.isFilterExpanded = false;
    },
    resetFilters() {
      this.dateTimeRange = {
        startDate: this.defaultDateTimeRange[0],
        endDate: this.defaultDateTimeRange[1]
      }
      this.brushDateTimeRange = this.dateTimeRange;
      this.selectedDateTimeRange = [];
      this.formattedDateRange = [];
      this.selectedMediaOwners = [];
      this.selectedFormatDelivers = [];
      this.selectedConurbations = [];
      this.selectedTvRegions = [];
      this.selectedCreativeNames = [];
      this.selectedSalesCodes = [];
      this.selectedFormatGroups = [];
      this.selectedLocationDescs = [];
      this.selectedEnvDescs = [];
      this.selectedAddresses = [];
      this.selectedPostCodes = [];
      this.selectedTowns = [];
      this.filtersActive = false;
      this.isFilterExpanded = false;
      this.debouncedUpdateFilters();
    },

    // Debounced update logic
    debouncedUpdateFilters: debounce(function () {
      this.dateTimeRangeFilter = this.selectedDateTimeRange;
      this.mediaOwnerFilter = this.selectedMediaOwners;
      this.formatDeliverFilter = this.selectedFormatDelivers;
      this.conurbationFilter = this.selectedConurbations;
      this.tvRegionFilter = this.selectedTvRegions;
      this.creativeNameFilter = this.selectedCreativeNames;
      this.salesCodeFilter = this.selectedSalesCodes;
      this.formatGroupFilter = this.selectedFormatGroups;
      this.locationDescFilter = this.selectedLocationDescs;
      this.envDescFilter = this.selectedEnvDescs;
      this.addressFilter = this.selectedAddresses;
      this.postCodeFilter = this.selectedPostCodes;
      this.townFilter = this.selectedTowns;
    }, 750),
    // #endregion
    // #region API Functions
    async fetchAPIData() {
      this.fetchingAPIData = true;
      this.loadedSearchTerm = '';
      await ReportingController.getPlayoutReportExpanded(this.searchTerm, 0, 'Daily')
        .then((res) => {
          this.loadedSearchTerm = this.searchTerm;
          if(res.data && res.data.data && res.data.data.length >= 1){
            this.apiData = res.data;
            console.log("this.apiData.supportInfo?.routeRequest", this.apiData.supportInfo?.routeRequest)
          }
          else{
            this.$root.$emit("snackbarError", "No Playout data found for " + this.searchTerm);
          }
        }).finally(() => {
          this.fetchingAPIData = false;
        });
    },

    async downloadReport(granularity) {
      this.downloadingReport = true;
      let res = await ReportingController.getPlayoutReportExport(this.loadedSearchTerm, 0, granularity).catch(async err => {

      }).finally(() => {
        this.downloadingReport = false;
      })
      const folderPath = decodeURI(res.headers['content-disposition'].split("filename=")[1].split(';')[0]).replaceAll('"', '').split('\\')
      const fileName = folderPath[folderPath.length - 1]
      const blob = new Blob([res.data])
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = fileName
      link.click()
      URL.revokeObjectURL(link.href)
    },
    // #endregion

    // #region For Dev Only
    showDataOnDevTextBox(dataToDisplay) {
      this.dataObject = JSON.stringify(dataToDisplay, null, 2);
      this.displayTextBox = true;
      this.$forceUpdate();
    },
    roughSizeOfObject(object) {
      const objectList = [];
      const stack = [object];
      let bytes = 0;

      while (stack.length) {
        const value = stack.pop();

        if (typeof value === 'boolean') {
          bytes += 4;
        } else if (typeof value === 'string') {
          bytes += value.length * 2;
        } else if (typeof value === 'number') {
          bytes += 8;
        } else if (typeof value === 'object' && objectList.indexOf(value) === -1) {
          objectList.push(value);

          for (const i in value) {
            stack.push(value[i]);
          }
        }
      }
      return bytes;
    },

    formatByteSize(bytes) {
      if (bytes < 1024) return bytes + ' bytes';
      else if (bytes < 1048576) return (bytes / 1024).toFixed(2) + ' KB';
      else if (bytes < 1073741824) return (bytes / 1048576).toFixed(2) + ' MB';
      else return (bytes / 1073741824).toFixed(2) + ' GB';
    },
    logMemoryUsage(label) {
      if (performance.memory) {
        const { usedJSHeapSize, totalJSHeapSize, jsHeapSizeLimit } = performance.memory;
        console.log(`[${label}] Memory Usage:`);
        console.log(`Used JS Heap: ${(usedJSHeapSize / 1048576).toFixed(2)} MB`);
        console.log(`Total JS Heap: ${(totalJSHeapSize / 1048576).toFixed(2)} MB`);
        console.log(`JS Heap Size Limit: ${(jsHeapSizeLimit / 1048576).toFixed(2)} MB`);
      } else {
        console.warn('Performance memory API is not available in this browser.');
      }
    }

    // #endregion
  },
  beforeDestroy() {
    // Clean up any event listeners or intervals
  }
}
</script>

<style scoped>
.reportrange-text[data-v-1ebd09d2] {
  background: #1e1e1e !important;
  border-radius: 5px;
  border-color: #545454;
  color: white;
  font-size: 15px;
  padding-top: 7px;
  transition: border-color 150ms;
}

.playout-filter {
  margin-top: 6px;
  height: 40px;
}

.playout-overline {
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 3px;
  font-family: "Roboto", sans-serif !important;
  text-transform: uppercase !important;
  color: #ffffff59;
  margin-top: 6px;
}

.playout-dashboard-container {
  background-color: #1e1e1e
}


.playout-dashboard-container-fullscreen {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;

}

/deep/ .v-select__selections {
  height: 40px;
  overflow-y: auto;
}
</style>