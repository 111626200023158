<template>
    <div style="color:black">
      <h3>{{ formatName }}</h3>
      <p>{{ mediaOwnerName }}</p>
      <p>Plays: {{ plays.toLocaleString() }}</p>
      <p v-if="impacts">Impacts: {{ Math.round(impacts).toLocaleString() }}</p>
      <p :style="{color: medianPlays > plays ? 'red' : 'green' }" v-if="medianPlays !== 0">Performance: {{ Math.round((plays / medianPlays) * 100) }}% ({{ (plays > medianPlays) ? (plays - medianPlays).toLocaleString() + ' plays more' : (medianPlays - plays).toLocaleString() + ' plays less' }} than the median)</p>
      <!-- Add any additional custom content or components here -->
    </div>
  </template>
  
  <script>
  export default {
    name: 'PlayoutMapPopup',
    props: {
      formatName: String,
      mediaOwnerName: String,
      plays: Number,
      impacts: Number,
      medianPlays: Number,
    },
  }
  </script>