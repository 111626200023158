<template>
  <div v-if="data.length > 0">
    <!-- <div>
      <div class="layoutJSON">
        Displayed as <code>[x, y, w, h]</code>:
        <div class="columns">
          <div class="layoutItem" v-for="item in layout" :key="item.i">
            <b>{{ item.i }}</b>: [{{ item.x }}, {{ item.y }}, {{ item.w }}, {{ item.h }}]
          </div>
        </div>
      </div>
    </div> -->
    <!-- Grid Layout -->
    <grid-layout :layout.sync="layout" :col-num="colNum" :row-height="rowHeight" :is-draggable="editMode"
      :is-resizable="editMode" :vertical-compact="true" :use-css-transforms="true" style="overflow: hidden;"
      :style="expanded ? '' : 'height: ' + (rowHeight + 20) + 'px'" @layout-updated="saveLayout">
      <grid-item v-for="item in layout" :static="item.static" :key="item.i" :x="item.x" :y="item.y" :w="item.w"
        :h="item.h" :i="item.i" :min-w="1" :max-w="3" :min-h="1" :max-h="1" :resize-handles="['e', 'w']"
        :v-if="expanded || item.y === 0">
        <PlayoutKPI :item="getItemData(item.i)" />
        <span v-if="editMode" class="remove" @click="removeItem(item.i)">
          <v-icon style="font-size: 15px;left: -1px;top: -1px;color: rgb(255 255 255 / 40%);">
            mdi-close
          </v-icon>
        </span>
        <span v-if="editMode" class="vue-resizable-handle">
          <v-icon style="left: -3px;top: -10px;color: rgb(255 255 255 / 40%);">
            mdi-resize-bottom-right
          </v-icon>
        </span>
      </grid-item>
    </grid-layout>

    <v-row justify="center" class="mr-3 ml-3" style="margin-top:6px; height:0px !important; margin-bottom: 0px;">
      <v-divider style="border-color: #ffffff2b"></v-divider>
      <v-btn v-show="layout.length > 3" @click="toggleExpand" small dense icon
        style="margin-top:-18px; width: 40px;height: 40px;">
        <v-icon>{{ expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
      <v-divider style="border-color: #ffffff2b"></v-divider>
      <!-- New dropdown to select an item to add -->
      <v-menu transition="scale-transition" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-show="availableItems.length > 0 && editMode" v-bind="attrs" v-on="on" small dense elevation="0"
            class="playout-kpi-button"
            style="right: 162px;">
            +{{ availableItems.length }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="item in availableItems" :key="item.name" @click="addItem(item.name)">
            <v-list-item-title v-text="item.name"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn v-show="editMode" @click="resetLayout" small dense elevation="0"
        class="playout-kpi-button"
        style="right: 106px;">
        <v-icon style="font-size: 14px;">mdi-refresh</v-icon>
      </v-btn>
      <v-btn @click="editMode = !editMode" small dense elevation="0"
        class="playout-kpi-button"
        style="right: 50px;"
        :style="{ 'background-color': editMode ? 'var(--v-anchor-base)' : '#1e1e1e' }">
        <v-icon style="font-size: 14px;">{{ editMode ? 'mdi-square-edit-outline' : 'mdi-square-edit-outline' }}</v-icon>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import { GridLayout, GridItem } from "vue-grid-layout";
import PlayoutKPI from './PlayoutKPI.vue';

export default {
  components: { GridLayout, GridItem, PlayoutKPI },

  data() {
    return {
      editMode: false,
      colNum: 3,
      rowHeight: 140,
      layout: [], // Will be populated in mounted
      availableItems: [], // Starts empty because all items are in the layout
      expanded: true
    };
  },

  props: {
    data: {
      type: Array,
      required: true
    }
  },

  watch: {
    data: async function (val, oldVal) {
      {
        if (val != oldVal)
          this.loadLayout();
      }
    },
  },

  mounted() {
    this.loadLayout();
    this.loadExpandState();
  },

  methods: {
    loadLayout() {
      const savedLayout = localStorage.getItem('kpi-layout');
      if (savedLayout) {
        const parsedLayout = JSON.parse(savedLayout);
        this.layout = parsedLayout.filter(layoutItem => this.getItemData(layoutItem.i));
        this.availableItems = this.data.filter(item => !this.layout.some(layoutItem => layoutItem.i === item.name));
      } else {
        this.initializeDefaultLayout();
      }
    },

    loadExpandState() {
      const savedExpandState = localStorage.getItem('kpi-expand-state');
      if (savedExpandState !== null) {
        this.expanded = JSON.parse(savedExpandState);
      }
    },

    initializeDefaultLayout() {
      this.layout = [];
      this.availableItems = [];

      this.data.forEach((item) => {
        const position = this.findFirstAvailablePosition(1, 1);
        if (position) {
          const newItem = {
            x: position.x,
            y: position.y,
            w: 1,
            h: 1,
            i: item.name,  // Use name as the identifier
          };
          this.layout.push(newItem);
        }
      });
      if (this.layout && this.layout.length <= 3) {
        this.expanded = true
      }
    },

    addItem(itemName) {
      if (itemName) {
        const position = this.findFirstAvailablePosition(1, 1);

        if (position) {
          const newItem = {
            x: position.x,
            y: position.y,
            w: 1,
            h: 1,
            i: itemName  // Use name as the identifier
          };

          this.layout.push(newItem);

          // Remove from available items
          this.availableItems = this.availableItems.filter(item => item.name !== itemName);
        } else {
          console.warn("No available space to add the item");
        }
      }
    },

    removeItem(itemName) {
      const itemIndex = this.layout.findIndex(item => item.i === itemName);

      if (itemIndex !== -1) {
        this.layout.splice(itemIndex, 1);
        const removedItemData = this.data.find(item => item.name === itemName);
        if (removedItemData) {
          this.availableItems.push(removedItemData);
        }
      }
    },

    saveLayout() {
      const savedLayout = localStorage.getItem('kpi-layout');
      if (savedLayout) {
        const parsedLayout = JSON.parse(savedLayout).filter(layoutItem => this.getItemData(layoutItem.i));
        console.log("Layout loaded:", parsedLayout, this.layout);
        // compare the two arrays
        if (JSON.stringify(parsedLayout) !== JSON.stringify(this.layout)) {
          localStorage.setItem('kpi-layout', JSON.stringify(this.layout));
        }
      }
      else {
        localStorage.setItem('kpi-layout', JSON.stringify(this.layout));
      }
    },

    resetLayout() {
      localStorage.removeItem('kpi-layout');
      this.initializeDefaultLayout();
    },

    getItemData(itemName) {
      return this.data.find(item => item.name === itemName) || null;
    },

    findFirstAvailablePosition(width, height) {
      let grid = Array(20).fill().map(() => Array(this.colNum).fill(false));

      // Mark occupied positions
      this.layout.forEach(item => {
        for (let y = item.y; y < item.y + item.h; y++) {
          for (let x = item.x; x < item.x + item.w; x++) {
            if (y < grid.length && x < this.colNum) {
              grid[y][x] = true;
            }
          }
        }
      });

      // Find first available position
      for (let y = 0; y < grid.length; y++) {
        for (let x = 0; x <= this.colNum - width; x++) {
          let canFit = true;
          for (let dy = 0; dy < height; dy++) {
            for (let dx = 0; dx < width; dx++) {
              if (y + dy >= grid.length || grid[y + dy][x + dx]) {
                canFit = false;
                break;
              }
            }
            if (!canFit) break;
          }
          if (canFit) {
            return { x, y };
          }
        }
      }
      return null; // No space found
    },

    toggleExpand() {
      this.expanded = !this.expanded;
      localStorage.setItem('kpi-expand-state', JSON.stringify(this.expanded));
    }
  }
};
</script>

<style scoped>
.playout-kpi-button {
  margin-top: -12px;
  width: 40px;
  height: 25px !important;
  position: absolute;
  background-color: #1e1e1e;
  border: 1px solid #444444;
  z-index: 10;
}
.vue-resizable-handle {
  z-index: 1000 !important;
}
</style>

<style>
.layoutJSON {
  background: #ddd;
  border: 1px solid black;
  margin-top: 10px;
  padding: 10px;
}

.columns {
  -moz-columns: 120px;
  -webkit-columns: 120px;
  columns: 120px;
}

/*************************************/
.remove {
  position: absolute;
  right: 2px;
  top: 0;
  cursor: pointer;
}

.vue-grid-item .resizing {
  opacity: 0.9;
}

.vue-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}

.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}

.vue-grid-item .minMax {
  font-size: 12px;
}

.vue-grid-item .add {
  cursor: pointer;
}
</style>