var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!!_vm.burst.workflowStatus && _vm.burst.workflowStatus.id !== 0)?_c('div',[_c('div',{staticClass:"d-flex align-center justify-end my-4"},_vm._l(([{name: 'Completed', color: '#499F68'}, {name: 'In progress', color: '#F464A9'}, {name: 'Pending', color: '#4AA6D5'}]),function(item){return _c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center text-caption mr-4"},'div',attrs,false),on),[_c('div',{staticClass:"mx-2",style:({ backgroundColor: item.color, width: '15px', height: '15px', borderRadius: '50%' })}),_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])],1)}),0),_c('div',{staticClass:"d-flex align-center",staticStyle:{"min-width":"100%","height":"25px","border-radius":"10px"}},_vm._l((_vm.burst.workflowStatus.categories),function(category,index){return _c('v-tooltip',{key:category.id,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',_vm._g(_vm._b({staticClass:"text-center",class:_vm.burst.workflowStatus.categories.length > 1 ? 'subIssue' : 'oneIssue',style:({
                    backgroundColor: _vm.filterColor(category.s),
                    width: ((100 / _vm.burst.workflowStatus.categories.length) + "%"),
                    opacity: hover ? 0.5 : 1,
                    height: '25px',
                    
                }),on:{"click":function($event){_vm.$store.state.Permissions.campaignMilestoneEdit ? _vm.openIssue(index, category, _vm.burst) : ''}}},'div',attrs,false),on),[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(category.n))])])]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v(_vm._s(category.n))])])}),1)]):_c('div',{staticClass:"my-2",staticStyle:{"width":"100%"}},[_c('span',[_vm._v("No profile milestone attached.")])])}
var staticRenderFns = []

export { render, staticRenderFns }