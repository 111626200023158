<template>
  <v-card width="700">
    <!-- Title -->
    <v-card-title class="white--text primary">
      Scheduling Options
    </v-card-title>

    <!-- Tab options -->
    <v-tabs
      v-model="tab"
      fixed-tabs
      background-color="primary"
      show-arrows
    >
      <v-tab v-if="$store.state.Permissions.triggerListEdit">Triggers</v-tab>
      <v-tab v-if="$store.state.Permissions.campaignScopeEdit">Campaign Scope</v-tab>
    </v-tabs>

    <!-- Tab items -->
    <v-tabs-items v-model="tab">
      <!-- Triggers -->
      <v-tab-item
        v-if="this.$store.state.Permissions.triggerListEdit"
        class="mx-7 py-2"
        style="height: 400px;"
      >
        <v-form
          ref="existingScreenEventForm"
          v-model="validExistingScreenEvent"
        >
          <v-row class="pa-0 ma-0 mt-2">
            <v-col 
              cols="12"
              class="ma-0 pa-0"
             
            >
              <v-select
                v-model="selectedTriggers"
                outlined
                multiple
                label="Available Triggers"
                item-text="friendlyName"
                item-value="paramName"
                :items="availableTriggers"
                @change="checkIfCanSave"
              />
            </v-col>
            <v-col
              cols="12"
              class="text-right"
              style ="margin-top: 224px;"
            >
              <v-btn
                @click="editBurstScreen('triggers')"
                :disabled="!dataHasChanged"
                :loading="submittingTrigger"
                color="primary"
                class="mx-1"
              >
                Save
              </v-btn>
              <v-btn
                color="red"
                @click="$emit('closeEditDialog')"
                class="mx-1"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>

      <!-- Campaign Scope -->
      <v-tab-item 
        v-if="$store.state.Permissions.campaignScopeEdit"
        class="mx-4 py-2"
      >
        <v-row class="ma-0 pa-0 py-2">
          <!--  Slot Break -->
          <v-col cols="6" class="ma-0 pa-0 pr-2">
            <v-text-field
              v-model="campaignBurstScreen.slotBreakSeconds"
              @change="editBurstScreen('slot')"
              outlined
              label="Slot Break (Seconds)"
            />
          </v-col>

          <!-- Slot duration -->
          <v-col cols="6" class="ma-0 pa-0 pl-2">
            <v-text-field
              v-model="campaignBurstScreen.slotDurationSeconds"
              @change="editBurstScreen('slot')"
              outlined
              label="Slot Duration (Seconds)"
            />
          </v-col>

          <!-- Event Type daily -->
          <v-col
            v-if="existingScreenCreateEvent.RepeatInterval !== 'Specific Days'"
            cols="12"
            class="ma-0 pa-0 mt-2"
          >
            <v-select
              v-model="existingScreenCreateEvent.RepeatInterval"
              @change="checkIfCanClose()"
              outlined
              label="Event type"
              :items="RepeatIntervals"
              :rules="requiredRule"
            />
          </v-col>

          <!-- Event Type specific days -->
          <v-col
            v-else
            cols="6"
            class="ma-0 pa-0"
          >
            <v-select
              v-model="existingScreenCreateEvent.RepeatInterval"
              @change="checkIfCanClose()"
              outlined
              label="Event type"
              :items="RepeatIntervals"
              :rules="requiredRule"
            />
          </v-col>

          <!-- Event Type specific days -> days of the week selector -->
          <v-col
            v-if="existingScreenCreateEvent.RepeatInterval === 'Specific Days'"
            cols="6"
            class="ma-0 pa-0"
          >
            <v-select
              v-model="existingScreenCreateEvent.dayOfWeek"
              @change="checkIfCanClose()"
              outlined
              persistent
              :rules="requiredRule"
              :items="[
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday',
              ]"
              color="primary"
              label="Day Of Week"
            />
          </v-col>

          <!-- Start time -->
          <v-col cols="6" class="ma-0 pa-0 pr-2">
            <div
              class="v-input v-input--is-label-active v-input--is-dirty v-input--is-readonly theme--dark v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined">
              <div class="v-input__control">
                <div class="v-input__slot">
                  <fieldset aria-hidden="true">
                    <legend style="width: 54.75px;"><span class="notranslate">&ZeroWidthSpace;</span></legend>
                  </fieldset>
                  <div class="v-text-field__slot">
                    <label for="input-2258" class="v-label v-label--active theme--dark"
                      style="left: 0px; right: auto; position: absolute;">Start Time</label>
                    <input type="time" required v-model="existingScreenCreateEvent.startTime" @change="checkIfCanClose()">
                  </div>
                </div>
              </div>
            </div>
          </v-col>


          <!-- End time -->
          <v-col cols="6" class="ma-0 pa-0 pl-2">
            <div
              class="v-input v-input--is-label-active v-input--is-dirty v-input--is-readonly theme--dark v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined">
              <div class="v-input__control">
                <div class="v-input__slot">
                  <fieldset aria-hidden="true">
                    <legend style="width: 54.75px;"><span class="notranslate">&ZeroWidthSpace;</span></legend>
                  </fieldset>
                  <div class="v-text-field__slot">
                    <label for="input-2259" class="v-label v-label--active theme--dark"
                      style="left: 0px; right: auto; position: absolute;">End Time</label>
                    <input type="time" required v-model="existingScreenCreateEvent.endTime" @change="checkIfCanClose()">
                  </div>
                </div>
              </div>
            </div>
          </v-col>

          <!-- Save / Cancel -->
          <v-col
            cols="12"
            class="text-right ma-0 pa-0 mb-2"
          >
            <v-btn
              color="primary"
              @click="editBurstScreen('schedule')"
              class="mx-1"
            >
              Add
            </v-btn>
            <v-btn
              color="red"
              @click="$emit('closeEditDialog')"
              class="mx-1"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          v-if="campaignBurstScreen.screenEvents.length > 0"
          class="pa-4"
        >
          <v-col cols="12">
            <v-data-table
              :headers="screenSchedulingEditHeaders"
              :items="campaignBurstScreen.screenEvents"
            >
              <template v-slot:item.actions="{ item }">
                <v-btn
                  class="m-2"
                  icon
                  small
                  color="primary"
                  @click="deleteExistingScheduledEventFromScreen(item)"
                >
                  <v-icon> mdi-delete </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
  import CampaignController from '@/services/controllers/Campaign'
  import moment from 'moment'
  const triggersList = require(`@/assets/${process.env.VUE_APP_TRIGGERS_VERSION}`)

  export default {
    props: ['campaignBurstScreen'],

    data () {
      return {
        submittingTrigger: false,
        dataHasChanged: false,
        time3: null,
        time4: null,
        tab: 0,
        availableTriggers: [],
        originalTriggers: [],
        selectedTriggers: [],
        selectedDate: { date: [] },
        originalDate: { date: [] },
        requiredRule: [(v) => !!v || 'Field is required'],
        existingScreenCreateEvent: {},
        startTimeMenuScreen: false,
        endTimeMenuScreen: false,
        burstDateRangePicker: null,
        RepeatIntervals: ['Specific Days', 'Daily'],
        screenSchedulingEditHeaders: [
          {
            text: 'Event type',
            align: 'start',
            sortable: true,
            value: 'repeatInterval',
          },
          {
            text: 'Day Of Week',
            align: 'start',
            sortable: true,
            value: 'dayOfWeek',
          },
          {
            text: 'Start Time',
            align: 'start',
            sortable: true,
            value: 'startTime',
          },
          {
            text: 'End Time',
            align: 'start',
            sortable: true,
            value: 'endTime',
          },
          {
            text: 'Actions',
            align: 'start',
            sortable: false,
            value: 'actions',
          },
        ],
        validExistingScreenEvent: false,
        deleteEventDialog: false
      }
    },

    watch: {
      campaignBurstScreen: function (newVal, oldVal) {
        // watch it
        this.selectedTriggers = newVal.triggerList
      },
    },

    created () {
      this.setScreenTriggers()
    },

    destroyed() {
      this.$emit('persistentEditDialogOff')
    },

    methods: {
      setScreenTriggers() {
        this.selectedTriggers = this.campaignBurstScreen.triggerList
        this.originalTriggers = [...this.selectedTriggers]

        for (var i = 0; i < triggersList.triggers.length; i++)
          this.availableTriggers.push(triggersList.triggers[i])
        
        // If screen has a start/end date, pre-populate the date picker
        if (this.campaignBurstScreen.startDate !== null) {
          this.selectedDate.date[0] = moment(this.campaignBurstScreen.startDate).format('YYYY-MM-DD')
          this.selectedDate.date[1] = moment(this.campaignBurstScreen.endDate).format('YYYY-MM-DD')

          this.originalDate.date = [...this.selectedDate.date]
        }
        else {
          this.selectedDate.date = null
          this.originalDate.date = null
        }
      },

      async deleteExistingScheduledEventFromScreen (item) {
        let screenEvents = JSON.parse(JSON.stringify(this.campaignBurstScreen.screenEvents))
        const index = screenEvents.findIndex(obj => JSON.stringify(obj) === JSON.stringify(item))

        screenEvents.splice(index, 1)

        // Splice selected event from array of events on selected screens.
        const burstObj = {
          id: this.campaignBurstScreen.id,
          screenEvents: screenEvents,
          startDate: this.selectedDate.date === null ? null : this.selectedDate.date[0] + 'T00:00:00.000Z',
          endDate: this.selectedDate.date === null ? null : this.selectedDate.date[1] + 'T00:00:00.000Z',
          slotBreakSeconds: this.campaignBurstScreen.slotBreakSeconds,
          slotDurationSeconds: this.campaignBurstScreen.slotDurationSeconds,
          triggerList: this.originalTriggers,
        }

        const res = await CampaignController.editBurstScreen(burstObj).catch((err) => {
          this.$root.$emit('snackbarError', ''+ err.response.data.message)
        })

        if(res) {
          this.campaignBurstScreen.screenEvents = screenEvents

          this.$emit('getCampaignByIdFromChild')
          this.$root.$emit('snackbarSuccess', 'Successfully removed scheduled event from screen.')
          this.deleteEventDialog = false
        }
      },

      async editBurstScreen (target, optionalIndex = null) {
        if(this.campaignBurstScreen.applyToAllScreens && optionalIndex == null) {
          for(let i = 0; i < this.campaignBurstScreen?.screens?.length; i++) {
            console.log("i",i);
            await this.editBurstScreen(target, i);
          }
          return;
        }
        this.submittingTrigger = true
        let maySubmit = false
        let screenEventsCopy = JSON.parse(JSON.stringify(this.campaignBurstScreen.screenEvents))

        // Add scheduled event to screen that is already in burst
        let burstObj = {}

        if(this.$store.state.Permissions.triggerListEdit)
          this.$refs.existingScreenEventForm.validate()

        // update campaignBurstScree obj
        if(optionalIndex == null) {
          burstObj = {
              id: this.campaignBurstScreen.id,
              slotBreakSeconds: this.campaignBurstScreen.slotBreakSeconds,
              slotDurationSeconds: this.campaignBurstScreen.slotDurationSeconds,
              startDate:
                this.selectedDate.date === null
                  ? null
                  : this.selectedDate.date[0] + 'T00:00:00.000Z',
              endDate:
                this.selectedDate.date === null
                  ? null
                  : this.selectedDate.date[1] + 'T00:00:00.000Z',
              triggerList: (target == 'triggers') ? this.selectedTriggers : this.originalTriggers,
            }
        } else {
          let i = optionalIndex;
          burstObj = {
              id: this.campaignBurstScreen.screens[i].id,
              slotBreakSeconds: this.campaignBurstScreen.screens[i].slotBreakSeconds,
              slotDurationSeconds: this.campaignBurstScreen.screens[i].slotDurationSeconds,
              startDate:
                this.selectedDate.date === null
                  ? null
                  : this.selectedDate.date[0] + 'T00:00:00.000Z',
              endDate:
                this.selectedDate.date === null
                  ? null
                  : this.selectedDate.date[1] + 'T00:00:00.000Z',
              triggerList: (target == 'triggers') ? this.selectedTriggers : this.originalTriggers,
            }
        }
        if(target == 'schedule')
        {
          if (Object.entries(this.existingScreenCreateEvent).length !== 0 && this.existingScreenCreateEvent.startTime != undefined 
                && this.existingScreenCreateEvent.endTime != undefined 
                && (this.existingScreenCreateEvent.RepeatInterval === 'Daily' || (this.existingScreenCreateEvent.RepeatInterval === 'Specific Days' && this.existingScreenCreateEvent.dayOfWeek != undefined))) {
                burstObj.screenEvents = this.campaignBurstScreen.screenEvents
                let screenEvent = 
                  {
                    dayOfWeek:
                      this.existingScreenCreateEvent.RepeatInterval === 'Daily'
                        ? 'Monday'
                        : this.existingScreenCreateEvent.dayOfWeek,
                    repeatInterval:
                      this.existingScreenCreateEvent.RepeatInterval ===
                      'Specific Days'
                        ? 'Weekly'
                        : this.existingScreenCreateEvent.RepeatInterval,
                    startTime: this.existingScreenCreateEvent.startTime,
                    endTime: this.existingScreenCreateEvent.endTime,
                  }
                  burstObj.screenEvents.push(screenEvent)
                  maySubmit = true
              }
              else
              {
                this.$root.$emit('snackbarError', 'Please check required fields.')
              }
          }
          else
          {
            maySubmit = true
          }
          if(maySubmit)
          {
            await CampaignController.editBurstScreen(burstObj).then((res) => {
              //this.selectedTriggers = []
              console.log("optionalIndex",optionalIndex);
              this.burstObj = {}
              this.campaignBurstScreen.screenEvents = res.data.screenEvents
              if(optionalIndex != null) {
                this.campaignBurstScreen.screens[optionalIndex].screenEvents = res.data.screenEvents;
              }
              this.$emit('updateScreenInBurstView', res.data, res.data.campaignBurst.id)
              //this.selectedTriggers = res.data.triggerList
              this.$root.$emit('snackbarSuccess', 'Successfully scheduled event to screen.')
              if(target === 'triggers') { 
                this.originalTriggers = [...this.selectedTriggers];
                if(optionalIndex == null || optionalIndex == this.campaignBurstScreen.screens.length - 1) {
                  this.$emit('closeEditDialog'); 
                  this.$store.dispatch('Clipboard/emptyClipboard'); }
                }
            })
            .catch((err) => {
              this.campaignBurstScreen.screenEvents = screenEventsCopy
              this.$root.$emit('snackbarError', ''+ err.response.data.message)
            })
          }
     
        this.checkIfCanSave()
        this.submittingTrigger = false
      },

      checkIfCanSave() {
        // Check if the triggers have changed
        this.dataHasChanged = false
        let foundAmt = 0

        this.originalTriggers.forEach(og => {
          let found = false
          this.selectedTriggers.forEach(sl => { found = (found || og === sl) ? true : false })
          foundAmt += (found) ? 1 : 0
        })

        this.dataHasChanged = (foundAmt !== this.originalTriggers.length || foundAmt !== this.selectedTriggers.length) ? true : false

        // Check if data has changed boolean to make set dialogs persistence
        if(this.dataHasChanged)
          this.$emit('persistentEditDialogOn')
        else
          this.$emit('persistentEditDialogOff')
      },

      checkIfCanClose() {
        // Check if the triggers have changed
        const { RepeatInterval, dayOfWeek, startTime, endTime } = this.existingScreenCreateEvent
        
        // Check if data has changed boolean to make set dialogs persistence
        if(
          (RepeatInterval && RepeatInterval.length > 0) ||
          (dayOfWeek && dayOfWeek.length > 0) ||
          (startTime && startTime.length > 0) ||
          (endTime && endTime.length > 0)
        )
          this.$emit('persistentEditDialogOn')
        else
          this.$emit('persistentEditDialogOff')
      }
    },
  }
</script>

<style scoped>
input[type="time"]::-webkit-calendar-picker-indicator{
  filter: invert(100%);
}
</style>